body{
  margin: 0%;
  background: linear-gradient(45deg, #0a0a0a, #3a4452);
  height: 100vh;
}

.box1
{
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

button{
  border: none;
  border-radius: 150px;
  height: 70px;
  font-size: larger;
  width: 70px;
  color: #ffffff;
  font-size: 20px;
  box-shadow: -8px -8px 15px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  background: transparent;
}

.container1{
  min-width: 50%;
  color: rgb(255, 255, 255);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.container2{

 margin-left: auto;
 margin-right: auto;
 border: 1px solid #717377;
 box-shadow: -8px -8px 15px rgba(113, 115, 119, 0.5);
 width: 350px;
 border-radius: 16px;
 height:420px;
}

.display
{ 
  box-shadow: 0px 3px 15px rgbs(84, 84, 84, 0.1);
  background: transparent;
  font-size: 40px;
  cursor: pointer;
  /* border-radius: 10%; */
  height: 100px;  
  border: none;
  color: aliceblue;

}

h1{
  color: aliceblue;
  margin-left: auto;
  margin-right: auto;
}
  

table{
  /* background-color: rgba(255, 255, 255); */
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

td{
  border-radius: 15px;
}

.operator{
  color: #6dee0a;
}

.special{
  background-color:#fb7c14;
}